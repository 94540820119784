<template>
  <div class="loading">
    <div class="box">
      <div class="loadingbox" v-loading="loading"></div>
      <div>
          <img src="../../static/img/logo.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang='less' scoped>
.loading {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: relative;
  z-index: 999999999999999;
  .box{
      width: 200px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      .loadingbox{
          width: 100px;
          height: 50px;
      }
  }
}
</style>
