<template>
  <div class="fucn">
    <div class="qiandaobox" @click="setAttendance">
      <img src="../../static/img/qiandao.png" alt="" />
    </div>
    <div class="erweimabox">
      <el-popover placement="left">
        <div class="imgboss">
          <img
            style="width: 129px; height: 147px"
            src="../../static/img/xcx.jpg"
            alt=""
          />
        </div>
        <img slot="reference" src="../../static/img/erweima.png" alt="" />
      </el-popover>
    </div>
    <div class="fabubox" @click="setreleasetype">
      <img src="../../static/img/fabu.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    setreleasetype() {
      this.$store.commit("changereleasetype", true);
    },
    setAttendance() {
      this.$api.common
        .Attendance({ token: window.localStorage.getItem("token") })
        .then((res) => {
          if (res.code == 200) {
            this.$store.commit("changeSigntype", true);
            this.$store.commit("changeranking", res.data.Ranking);
            this.$store.commit("changeContinuousCheckIn", res.data.TotalNum);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang='less' scoped>
.fucn {
  width: 64px;
  display: flex;
  flex-direction: column;
  .qiandaobox {
    width: 100%;
    img {
      width: 100%;
      cursor: pointer;
    }
  }
  .erweimabox {
    width: 100%;
    img {
      width: 100%;
      cursor: pointer;
    }
  }
  .fabubox {
    width: 100%;
    img {
      width: 100%;
      cursor: pointer;
    }
  }
  .imgboss {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
