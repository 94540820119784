<template>
  <div class="release">
    <div class="relebox">
      <div class="close">
        <i class="el-icon-close" @click="setreleasetype"></i>
      </div>
      <el-input
        type="textarea"
        :rows="10"
        placeholder="分享你的文玩之乐..."
        v-model="form.Content"
      >
      </el-input>
      <div class="buttom">
        <div class="fuccon">
          <!-- 表情 -->
          <el-popover placement="bottom">
            <div class="functionemo">
              <img
                v-for="(item, index) in emoList"
                :key="index"
                :src="getImgUrl(item)"
                alt=""
                @click="setemo(item)"
              />
            </div>
            <img slot="reference" src="../../static/img/biaoqing.png" alt="" />
          </el-popover>
          <!-- 图片 -->
          <el-popover placement="bottom">
            <el-upload
              action="https://up-z2.qiniup.com/"
              :data="postData"
              list-type="picture-card"
              :on-remove="handleRemove"
              :on-success="handleAvatarSuccess"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <img slot="reference" src="../../static/img/oic.png" alt="" />
          </el-popover>
          <!-- 图片 -->
          <el-popover placement="bottom">
            <el-input
              v-model="form.Title"
              maxlength="20"
              placeholder="请输入内容"
            ></el-input>
            <img slot="reference" src="../../static/img/huati.png" alt="" />
          </el-popover>
        </div>
        <div class="releasebutton" @click="AppletsFindInsert">发布</div>
      </div>
    </div>
  </div>
</template>

<script>
import emo from "../../static/js/emo";
export default {
  data() {
    return {
      centerDialogVisible: true,
      textarea: "",
      emoList: [],
      form: {
        token: window.localStorage.getItem("token"),
        Kehuduan: 3,
        Content: "",
        Image: "",
        Video: "",
        Audio: "",
        Title: "",
        Label: "",
      },
      postData: {
        token: "", //七牛云上传token
        region: "SCN",
      },
    };
  },
  created() {
    this.emoList = emo;
    this.qny_think();
    if (this.$route.query.topic) {
      this.form.Title = this.$route.query.topic;
    } else {
      this.form.Title = "";
    }
  },
  methods: {
    AppletsFindInsert() {
      if (this.form.Content == "" && this.form.Image == "") {
        this.$message({
          message: "内容不能为空",
          type: "warning",
        });
        return false;
      } else {
        if (this.form.Image != "") {
          this.form.Image = this.form.Image.substr(1);
        }
        this.$api.common.AppletsFindInsert(this.form).then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "发布成功,正在跳转首页",
              type: "success",
            });
            setTimeout(() => {
              window.location.href = `http://www.yiwuwenwan.com/#/index`;
              window.document.location.reload();
            }, 1000);
          }
        });
      }
    },
    // 插入表情
    setemo(item) {
      this.form.Content = this.form.Content + "[" + item.name + "]";
    },
    // 关闭
    setreleasetype() {
      this.$store.commit("changereleasetype", false);
    },
    // 拼接表情
    getImgUrl(i) {
      return require("../../static/emo/" + i.src);
    },
    // 上传图片成功
    handleAvatarSuccess(res, file) {
      this.form.Image = this.form.Image + "," + `${res.key}`;
    },
    //删除图片
    handleRemove(file, fileList) {
      if (fileList.length > 0) {
        let str = "";
        let array = this.form.Image.split(",");
        for (let index = 0; index < array.length; index++) {
          for (let n = 0; n < fileList.length; n++) {
            if (array[index] == fileList[n].response.key) {
              str = str + "," + fileList[n].response.key;
            }
          }
        }
        this.form.Image = str;
      } else {
        this.form.Image = "";
      }
    },
    // 七牛云上传token
    qny_think() {
      this.$api.common
        .qny_think({ token: window.localStorage.getItem("token") })
        .then((res) => {
          if (res.code == 200) {
            this.postData.token = res.data.token;
          }
        });
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-textarea__inner {
  border: 1px solid #e4d9c7 !important;
}
.functionemo {
  width: 500px;
  img {
    cursor: pointer;
  }
}
.release {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 0;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  .relebox {
    margin-top: -200px;
    width: 645px;
    min-height: 345px;
    background: #ffffff;
    box-shadow: 0px 0px 15px 1px rgba(156, 122, 53, 0.22);
    border-radius: 15px;
    box-sizing: border-box;
    padding: 20px;
    .close {
      width: 100%;
      text-align: right;
      font-size: 20px;
      margin-bottom: 10px;
      * {
        cursor: pointer;
        color: #818181;
      }
    }
    .buttom {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      .fuccon {
        box-sizing: border-box;
        // padding-left: 35px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        * {
          margin-left: 10px;
          cursor: pointer;
        }
      }
      .releasebutton {
        width: 87px;
        height: 32px;
        background: #e6bf84;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: NotoSansHans;
        font-weight: 300;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
</style>
