/**
 * api统一接口
 */
import common from './common'

// 导出接口
export default {
    common
}


