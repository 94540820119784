<template>
  <div>
    <transition name="el-fade-in-linear">
      <div class="qiandaofixend" v>
        <div class="qiandaofixendbox">
          <div class="close" @click="setSigntype">
            <i class="el-icon-close"></i>
          </div>
          <div class="bgc">
            <div class="month">{{ month }}月</div>
            <div class="day">{{ day }}</div>
          </div>
          <div class="succec">签到成功</div>
          <!-- <div class="ranking">
            今日第{{ this.$store.state.ranking }}名，已连续签到{{
              this.$store.state.ContinuousCheckIn
            }}天
          </div> -->
          <div class="ranking">
            已连续签到{{ this.$store.state.ContinuousCheckIn }}天
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      month: "",
      day: "",
    };
  },
  created() {
    var myDate = new Date();
    this.month = myDate.getMonth() + 1;
    this.day = myDate.getDate();
  },
  methods: {
    setSigntype() {
      this.$store.commit("changeSigntype", false);
    },
  },
};
</script>

<style lang='less' scoped>
.qiandaofixend {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .qiandaofixendbox {
    width: 548px;
    height: 508px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px;
    overflow: hidden;

    .close {
      float: right;
      font-size: 30px;
      cursor: pointer;
      color: #818181;
    }

    .bgc {
      width: 409px;
      height: 308px;
      background: url("../../static//img/success.png");
      background-size: 100%;
      margin: auto;
      margin-top: 50px;
      overflow: hidden;
      text-align: center;

      .month {
        font-size: 26px;
        font-family: NotoSansHans;
        font-weight: normal;
        color: #ffffff;
        margin-top: 95px;
        margin-bottom: 20px;
      }

      .day {
        font-size: 45px;
        font-family: NotoSansHans;
        font-weight: 500;
        color: #818181;
      }
    }

    .succec {
      width: 100%;
      text-align: center;
      font-size: 26px;
      font-family: NotoSansHans;
      font-weight: 400;
      color: #d86666;
      margin-top: 20px;
    }

    .ranking {
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-family: NotoSansHans;
      font-weight: 300;
      color: #818181;
      margin-top: 20px;
    }
  }
}
</style>
