<template>
  <div id="app">
    <app-header v-if="header_show && !loading"></app-header>
    <transition name="el-fade-in-linear">
      <router-view
        v-if="header_show && !loading"
        v-on:public_header="public_header"
      ></router-view>
    </transition>
    <Login v-if="Login && !loading" />
    <Loading v-if="loading" />
    <div class="Commonfixend">
      <Common />
    </div>
    <Release v-if="!loading && releasetype" />
    <Signttype v-if="Signtype" />
  </div>
</template>
<script>
import Header from "./components/Common/Header";
import Login from "./view/Login/index.vue";
import Loading from "./components/Common/loading.vue";
import Common from "./components/Common/common.vue";
import Release from "./components/Common/release.vue";
import Signttype from "./components/Common/Signtype.vue";
export default {
  name: "App",
  data() {
    return {
      header_show: true,
      Login: false,
      loading: false,
      releasetype: false,
      Signtype: false,
    };
  },
  components: {
    "app-header": Header,
    Login,
    Loading,
    Common,
    Release,
    Signttype,
  },
  computed: {
    LoginStatus() {
      return this.$store.state.LoginStatus;
    },
    LoadingStatus() {
      return this.$store.state.loading;
    },
    releasetypeStatus() {
      return this.$store.state.releasetype;
    },
    SignttypeStatus() {
      return this.$store.state.Signtype;
    },
  },

  watch: {
    LoginStatus: function (newval, oldval) {
      this.Login = newval;
    },
    LoadingStatus: function (newval, oldval) {
      this.loading = newval;
    },
    releasetypeStatus: function (newval, oldval) {
      this.releasetype = newval;
    },
    SignttypeStatus: function (newval, oldval) {
      this.Signtype = newval;
    },
  },
  created() {
    // this.$store.commit("changeDataMut", true);
    if (!window.localStorage.getItem("token")) {
      this.$store.commit("changeDataMut", true);
    } else {
      this.$store.commit("changeDataMut", false);
    }
    this.$store.commit("changeLoading", true);
    setTimeout(() => {
      this.$store.commit("changeLoading", false);
    }, 1000);
  },
  methods: {
    //是否显示头部
    public_header: function (bool) {
      this.header_show = bool;
    },

    //  this.$emit('public_header',false); 在子页面使用可以改变是否显示头部
  },
};
</script>
 
<style lang="less">
.Commonfixend {
  position: fixed;
  bottom: 100px;
  right: 200px;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: #fff;
  font-family: "微软雅黑", "黑体", "宋体";
  font-size: 12px;
  height: 36px;
}
#app {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background: url("./static/img/bg.png") no-repeat center;
  background-size: cover;
  scrollbar-width: none;
  // overflow: hidden;
}
#app::-webkit-scrollbar {
  display: none;
}

body::-webkit-scrollbar {
  display: none;
}
</style>
