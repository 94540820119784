// 导入vue及vuex
import Vue from 'vue'
import Vuex from 'vuex'
// 挂载vuex
Vue.use(Vuex)

// 创建vuex对象并向外暴露
export default new Vuex.Store({
    // 全局属性变量
    //state       {{ this.$store.state."键值"}} 可以调用键值
    state: {
        LoginStatus: false,
        loading: false,
        pageKey: "",
        MemberCode: "",
        releasetype: false,
        Signtype:false,//签到
        ranking:"",//签到排名
        ContinuousCheckIn:"",//连续签到天
        list:[],//搜索数组
        total:0,//搜索总数
    },
    // mutations全局同步方法, 调用方法,this.$store.commit("xxx")
    //    this.$store.commit('changeDataMut',10) 修改data改成10
    mutations: {
        changeDataMut(state, params) {
            state.LoginStatus = params
        },
        changeLoading(state, params) {
            state.loading = params
        },
        changepageKey(state, params) {
            state.pageKey = params
        },
        changeMemberCode(state, params) {
            state.MemberCode = params
        },
        changereleasetype(state, params) {
            state.releasetype = params
        },
        changeSigntype(state, params) {
            state.Signtype = params
        },
        changeranking(state, params) {
            state.ranking = params
        },
        changeContinuousCheckIn(state, params) {
            state.ContinuousCheckIn = params
        },
        changelist(state, params) {
            state.list = params
        },
        changetotal(state, params) {
            state.total = params
        },
    },
    // 异步方法 调用方法,this.$store.dispatch("xxx") 
    //actions this.$store.dispatch('changeDataAct',100)
    // action函数内部commit触发mutation
    actions: {
        changeDataAct(context, params) {  //context是一个对象，从它里面把咱们需要的commit方法解构出来
            let { commit } = context
            commit('changeDataMut', params)
        }

    },
    // Vuex属性计算,在视图里面当变量使用 
    // 使用 {{this.$store.getters.doubleGet}}
    getters: {
        doubleGet(state) {
            return !state.LoginStatus
        }

    },
    // 模块化注册
    modules: {
    }
})
