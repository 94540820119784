<template>
  <div class="login">
    <div class="lofinbox">
      <div class="close">
        <i class="el-icon-close" @click="setLoginStatus"></i>
      </div>
      <div class="register" v-if="loginboxtype">注册</div>
      <div class="logintitle" v-if="!loginboxtype">登录</div>
      <!-- 手机号注册 -->
      <div class="from" v-if="loginboxtype">
        <div class="phone">
          <img src="../../static/img/shouji.png" alt="" />
          <el-input
            v-model="ruleFrom.Phone"
            placeholder="请输入手机号"
          ></el-input>
        </div>
        <el-divider></el-divider>
        <div class="phpasswordone">
          <img src="../../static/img/mima.png" alt="" />
          <el-input
            placeholder="请输入密码"
            v-model="ruleFrom.Password"
            show-password
          ></el-input>
        </div>
        <el-divider></el-divider>
        <div class="verification">
          <el-input
            v-model="ruleFrom.yzm_code"
            placeholder="请输入验证码"
          ></el-input>
          <div class="send" v-show="show" @click="getCode">获取验证码</div>
          <div class="send" v-show="!show">{{ count }}s后再次获取</div>
        </div>
        <el-divider></el-divider>
        <!-- <div class="doubt">收不到验证码？</div> -->
        <div class="button" @click="goregister">立即注册</div>
        <div class="gologin" @click="gotoregister">去登录</div>
      </div>
      <!-- 手机号登录 -->
      <div class="from" v-if="!loginboxtype">
        <div v-if="!WeChattype">
          <div class="phone">
            <img src="../../static/img/shouji.png" alt="" />
            <el-input
              v-model="form.Phone"
              placeholder="请输入手机号"
            ></el-input>
          </div>
          <el-divider></el-divider>
          <div class="phpasswordone" v-if="!phonepasswordtype">
            <img src="../../static/img/mima.png" alt="" />
            <el-input
              placeholder="请输入密码"
              v-model="form.Password"
              show-password
            ></el-input>
          </div>
          <el-divider v-if="!phonepasswordtype"></el-divider>
          <div class="verification" v-if="phonepasswordtype">
            <el-input
              v-model="form.phone"
              placeholder="请输入验证码"
            ></el-input>
            <div class="send" v-show="show" @click="getCode">获取验证码</div>
            <div class="send" v-show="!show">{{ count }}s后再次获取</div>
          </div>
          <el-divider v-if="phonepasswordtype"></el-divider>
          <!-- <div class="newdoubt">
            <span style="color: #ac7420" @click="setphonepasswordtype">{{
              phonepasswordtype ? "手机号密码登录" : "短信登录"
            }}</span
            ><span>收不到验证码？</span>
          </div> -->
          <div class="button" @click="gologin">登录</div>
          <div class="gologin" @click="gotoregister">快速注册</div>
          <!-- <div class="gotoweixin">
            <img @click="setWeChattype" src="../../static/img/wx.png" alt="" />
          </div> -->
        </div>
        <!-- 微信登录 -->
        <!-- <div v-if="WeChattype" class="WeChat">微信登录</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        Phone: "",
        Password: "",
      },
      ruleFrom: {
        Phone: "",
        yzm_id: "",
        yzm_code: "",
        Password: "",
        InvitationCode: "",
      },
      show: true,
      count: "",
      timer: null,
      loginboxtype: false, //注册状态 true登录 false注册
      phonepasswordtype: false, //true手机密码登录 false手机验证码登录
      WeChattype: false,
    };
  },
  created() {},
  methods: {
    // 验证码倒计时
    getCode() {
      var pattern = /^1[34578]\d{9}$/;
      if (pattern.test(this.ruleFrom.Phone)) {
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
          this.$api.common
            .membersms({ Phone: this.ruleFrom.Phone })
            .then((res) => {
              if (res.code == 200) {
                this.ruleFrom.yzm_id = res.data;
              }
            });
        }
      } else {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
        });
        return false;
      }
      const TIME_COUNT = 60;
    },
    // 去注册
    goregister() {
      if (this.ruleFrom.Phone == "") {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
        });
        return false;
      }
      if (this.ruleFrom.yzm_code == "") {
        this.$message({
          message: "请输入验证码",
          type: "warning",
        });
        return false;
      }
      if (this.ruleFrom.Password == "") {
        this.$message({
          message: "请输入密码",
          type: "warning",
        });
        return false;
      }
      this.$api.common.MemberRegistered(this.ruleFrom).then((res) => {
        console.log(res);
        if (res.code == 200) {
          window.localStorage.setItem("token", res.data.token);
          window.localStorage.setItem("Avatar", res.data.Avatar);
          window.localStorage.setItem("userinfo", JSON.stringify(res.data));
          this.$store.commit("changeDataMut", false);

          window.document.location.reload();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 手机号密码登录
    gologin() {
      this.$api.common.login_app(this.form).then((res) => {
        console.log(res.data);
        if (res.code == 200) {
          window.localStorage.setItem("token", res.data.token);
          window.localStorage.setItem("Avatar", res.data.Avatar);
          window.localStorage.setItem("userinfo", JSON.stringify(res.data));
          this.$store.commit("changeDataMut", false);
          window.document.location.reload();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 退出登录
    setLoginStatus() {
      this.$store.commit("changeDataMut", false);
    },
    // 手机密码 手机验证码 切换
    setphonepasswordtype() {
      this.phonepasswordtype = !this.phonepasswordtype;
    },
    // 去注册或登录状态切换
    gotoregister() {
      this.loginboxtype = !this.loginboxtype;
    },
    // 微信登录或手机登录
    setWeChattype() {
      this.WeChattype = !this.WeChattype;
    },
  },
};
</script>

<style lang='less' scoped>
@import url("./index.less");
/deep/.el-divider--horizontal {
  background-color: #f8f8f8;
}
</style>
