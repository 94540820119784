/*
* 路由对象模块
* */
import Vue from 'vue'
import VueRouter from 'vue-router'

//申明使用插件
Vue.use(VueRouter)

const router = new VueRouter({
    linkActiveClass: 'is-active',
    mode: 'hash', //改成hash模式
    routes: [
        {
            path: '/',
            name: 'index',
            component: () => import('../view/index/index.vue'),
            children: [
                {
                    path: '/index',
                    name: 'index',
                    component: () => import('../view/index/index.vue'),
                    meta: {
                        title: "首页"
                    }
                },

            ]
        },
        {
            path: '/Postdetails',
            name: 'Postdetails',
            component: () => import('../view/Postdetails/index.vue'),
            meta: {
                title: "帖子详情"
            }
        },
        {
            path: '/driedfood',
            name: 'driedfood',
            component: () => import('../view/driedfood/index.vue'),
            meta: {
                title: "干货"
            }
        },
        {
            path: '/information',
            name: 'information',
            component: () => import('../view/information/index.vue'),
            meta: {
                title: "信息"
            }
        },
        {
            path: '/userinfo',
            name: 'userinfo',
            component: () => import('../view/userinfo/index.vue'),
            meta: {
                title: "用户主页"
            }
        },
        {
            path: '/Otherspersonal',
            name: 'Otherspersonal',
            component: () => import('../view/Otherspersonal/index.vue'),
            meta: {
                title: "他人用户主页"
            }
        },
        {
            path: '/conversation',
            name: 'conversation',
            component: () => import('../view/conversation/index.vue'),
            meta: {
                title: "话题列表"
            }
        },
        {
            path: '/Searchposts',
            name: 'Searchposts',
            component: () => import('../view/Searchposts/index.vue'),
            meta: {
                title: "搜索帖子"
            }
        },
    ]
})
// router.beforeEach((to, from, next) => {
//     let TOKEN = localStorage.getItem("TOKEN");
//     if (TOKEN) {
//         next();
//     } else {
//         if (to.path == '/login') {
//             next();
//         } else {
//             next('/login');
//         }
//     }
// })
export default router