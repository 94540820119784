import Vue from 'vue'
import App from './App.vue'
import router from './router'
import less from 'less'
import 'lib-flexible'
import api from '../src/api/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 导入
import store from './sotre/index'
// 挂载到Vue实力上，全局可通过this.$store进行调用
Vue.prototype.$store = store

Vue.use(ElementUI);
Vue.prototype.$api = api
Vue.prototype.RichContent = function (val) {
    let html = val.replace(/\<img/gi, '<img class="rich-img" ')
        .replace(/\[emoji(.+?)\]/gi, "<img class='icon-emoji-item' style='width:15px;height:15px' src='https://www.xsdyiwu.com/emoji/emoji$1.png' />")
        .replace(/\<p/gi, '<p class="rich-p"')
        .replace(/\<span/gi, '<span class="rich-span"')
        ;
        // html=html.replaceAll('\n','<br/>'); 
    return html
};
Vue.prototype.msg= function (msg) {  
    if (msg.length > 40) {
        return msg.substring(0, 40) + "...";
      } else {
        return msg;
      }

};
Vue.use(less)
let vue = new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
export default vue