// 公共模块接口
import { post, get } from './http.js'

const commonApi = {

    // 用户手机号账号密码登录
    login_app(params) {
        return post('api/member/login_app', params)
    },
    // 用户注册
    MemberRegistered(params) {
        return post('api/member/MemberRegistered', params)
    },
    // 手机号验证码
    membersms(params) {
        return post('api/member/sms', params)
    },
    // 首页
    FindList(params) {
        return post('api/find/FindList', params)
    },
    // 圈子帖子搜索
    FindSearchList(params) {
        return post('api/find/FindSearchList', params)
    },
    // 圈子帖子详情
    FindDetail(params) {
        return post('api/find/FindDetail', params)
    },
    // 用户个人主页 
    MemberInfo(params) {
        return post('api/find/MemberInfo', params)
    },
    // 用户个人主页帖子列表 
    MemberFindList(params) {
        return post('api/find/MemberFindList', params)
    },
    // 发布帖子评论 
    AppletsFindCommentInsert(params) {
        return post('api/find/AppletsFindCommentInsert', params)
    },
    // 发布帖子评论回复 
    AppletsFindFindCommentReplyInsert(params) {
        return post('api/find/AppletsFindFindCommentReplyInsert', params)
    },
    // 七牛云上传token
    qny_think(params) {
        return post('api/index/qny_think', params)
    },
    // 帖子点赞
    FindPraiseInsert(params) {
        return post('api/find/FindPraiseInsert', params)
    },
    // 点赞取消
    FindPraiseDelete(params) {
        return post('api/find/FindPraiseDelete', params)
    },
    // 关注用户
    MemberAttentionAdd(params) {
        return post('api/member/MemberAttentionAdd', params)
    },
    // 取消关注
    MemberAttentionDelete(params) {
        return post('api/member/MemberAttentionDelete', params)
    },
    // 提醒消息列表
    ReminderList(params) {
        return post('api/message/ReminderList', params)
    },
    // 设置-查询个人资料 
    apimemberInfo(params) {
        return post('api/member/info', params)
    },
    // 设置-修改个人资料 
    info_update(params) {
        return post('api/member/info_update', params)
    },
    // 个人中心推荐用户（PC）
    recommended_attention_list(params) {
        return post('api/member/recommended_attention_list', params)
    },
    // 圈子帖子发布
    AppletsFindInsert(params) {
        return post('api/find/AppletsFindInsert', params)
    },
    // 圈子干货 
    DryGoodsList(params) {
        return post('api/find/DryGoodsList', params)
    },
    // 用户签到 
    Attendance(params) {
        return post('api/member/Attendance', params)
    },
    // 话题查询 
    FindTitleList(params) {
        return post('api/find/FindTitleList', params)
    },
    // 话题查询 
    index(params) {
        return post('api/member/index', params)
    },
    // 话题查询 
    hot_topic(params) {
        return post('api/find/hot_topic', params)
    },
    // 删除帖子回复 
    FindCommentReplyDelete(params) {
        return post('api/find/FindCommentReplyDelete', params)
    },
    // 删除帖子评论 
    FindCommentDelete(params) {
        return post('api/find/FindCommentDelete', params)
    },
     // 帖子删除 
     FindDelete(params) {
        return post('api/member/FindDelete', params)
    },
}

export default commonApi