const emo = [
    {
        name: "emoji_01",
        src: "emoji_01.png"
    },
    {
        name: "emoji_02",
        src: "emoji_02.png"
    },
    {
        name: "emoji_03",
        src: "emoji_03.png"
    },
    {
        name: "emoji_04",
        src: "emoji_04.png"
    },
    {
        name: "emoji_05",
        src: "emoji_05.png"
    },
    {
        name: "emoji_06",
        src: "emoji_06.png"
    },
    {
        name: "emoji_07",
        src: "emoji_07.png"
    },
    {
        name: "emoji_08",
        src: "emoji_08.png"
    },
    {
        name: "emoji_09",
        src: "emoji_09.png"
    },
    {
        name: "emoji_10",
        src: "emoji_10.png"
    },
    {
        name: "emoji_11",
        src: "emoji_11.png"
    },
    {
        name: "emoji_12",
        src: "emoji_12.png"
    },
    {
        name: "emoji_13",
        src: "emoji_13.png"
    },
    {
        name: "emoji_14",
        src: "emoji_14.png"
    },
    {
        name: "emoji_15",
        src: "emoji_15.png"
    },
    {
        name: "emoji_16",
        src: "emoji_16.png"
    },
    {
        name: "emoji_17",
        src: "emoji_17.png"
    },
    {
        name: "emoji_18",
        src: "emoji_18.png"
    },
    {
        name: "emoji_19",
        src: "emoji_19.png"
    },
    {
        name: "emoji_20",
        src: "emoji_20.png"
    },
    {
        name: "emoji_21",
        src: "emoji_21.png"
    },
    {
        name: "emoji_22",
        src: "emoji_22.png"
    },
    {
        name: "emoji_23",
        src: "emoji_23.png"
    },
    {
        name: "emoji_24",
        src: "emoji_24.png"
    },
    {
        name: "emoji_25",
        src: "emoji_25.png"
    },
    {
        name: "emoji_26",
        src: "emoji_26.png"
    },
    {
        name: "emoji2_01",
        src: "emoji2_01.png"
    },
    {
        name: "emoji2_02",
        src: "emoji2_02.png"
    },
    {
        name: "emoji2_03",
        src: "emoji2_03.png"
    },
    {
        name: "emoji2_04",
        src: "emoji2_04.png"
    },
    {
        name: "emoji2_05",
        src: "emoji2_05.png"
    },
    {
        name: "emoji2_06",
        src: "emoji2_06.png"
    },
    {
        name: "emoji2_07",
        src: "emoji2_07.png"
    },
    {
        name: "emoji2_08",
        src: "emoji2_08.png"
    },
    {
        name: "emoji2_09",
        src: "emoji2_09.png"
    },
    {
        name: "emoji2_10",
        src: "emoji2_10.png"
    },
    {
        name: "emoji2_11",
        src: "emoji2_11.png"
    },
    {
        name: "emoji2_12",
        src: "emoji2_12.png"
    },
    {
        name: "emoji2_13",
        src: "emoji2_13.png"
    },
    {
        name: "emoji2_14",
        src: "emoji2_14.png"
    },
    {
        name: "emoji2_15",
        src: "emoji2_15.png"
    },
    {
        name: "emoji2_16",
        src: "emoji2_16.png"
    },
    {
        name: "emoji2_17",
        src: "emoji2_17.png"
    },
    {
        name: "emoji2_18",
        src: "emoji2_18.png"
    },
    {
        name: "emoji2_19",
        src: "emoji2_19.png"
    },
    {
        name: "emoji2_20",
        src: "emoji2_20.png"
    },
    {
        name: "emoji2_21",
        src: "emoji2_21.png"
    },
    {
        name: "emoji2_22",
        src: "emoji2_22.png"
    },
    {
        name: "emoji2_23",
        src: "emoji2_23.png"
    },
    {
        name: "emoji2_24",
        src: "emoji2_24.png"
    },
    {
        name: "emoji2_25",
        src: "emoji2_25.png"
    },
    {
        name: "emoji2_26",
        src: "emoji2_26.png"
    },
    {
        name: "emoji2_27",
        src: "emoji2_27.png"
    },
    {
        name: "emoji3_01",
        src: "emoji3_01.png"
    },
    {
        name: "emoji3_02",
        src: "emoji3_02.png"
    },
    {
        name: "emoji3_03",
        src: "emoji3_03.png"
    },
    {
        name: "emoji3_04",
        src: "emoji3_04.png"
    },
    {
        name: "emoji3_05",
        src: "emoji3_05.png"
    },
    {
        name: "emoji3_06",
        src: "emoji3_06.png"
    },
    {
        name: "emoji3_07",
        src: "emoji3_07.png"
    },
    {
        name: "emoji3_08",
        src: "emoji3_08.png"
    },
    {
        name: "emoji3_09",
        src: "emoji3_09.png"
    },
    {
        name: "emoji3_010",
        src: "emoji3_010.png"
    },
    {
        name: "emoji3_11",
        src: "emoji3_11.png"
    },
    {
        name: "emoji3_12",
        src: "emoji3_12.png"
    },
    {
        name: "emoji3_13",
        src: "emoji3_13.png"
    },
    {
        name: "emoji3_14",
        src: "emoji3_14.png"
    },
    {
        name: "emoji3_15",
        src: "emoji3_15.png"
    },
    {
        name: "emoji3_16",
        src: "emoji3_16.png"
    },
    {
        name: "emoji3_17",
        src: "emoji3_17.png"
    },
    {
        name: "emoji3_18",
        src: "emoji3_18.png"
    },
    {
        name: "emoji3_19",
        src: "emoji3_19.png"
    },
    {
        name: "emoji3_20",
        src: "emoji3_20.png"
    },
    {
        name: "emoji3_21",
        src: "emoji3_21.png"
    },
    {
        name: "emoji3_22",
        src: "emoji3_22.png"
    },
    {
        name: "emoji3_23",
        src: "emoji3_23.png"
    },
    {
        name: "emoji3_24",
        src: "emoji3_24.png"
    },
    {
        name: "emoji3_25",
        src: "emoji3_25.png"
    },
    {
        name: "emoji3_26",
        src: "emoji3_26.png"
    },
    {
        name: "emoji3_27",
        src: "emoji3_27.png"
    },
    {
        name: "emoji3_28",
        src: "emoji3_28.png"
    },
    {
        name: "emoji3_29",
        src: "emoji3_29.png"
    },
    {
        name: "emoji3_30",
        src: "emoji3_30.png"
    },

]
export default emo