<template>
  <div class="header">
    <div class="shadow">
      <div class="nav">
        <div class="left">
          <img
            src="../../static/img/quanzi.png"
            v-if="routetype == 0"
            alt=""
            @click="gotoindex"
          />
          <span v-if="routetype == 0" @click="gotodriedfood">干货</span>
          <span v-if="routetype == 1" @click="gotoindex">圈子</span>
          <img v-if="routetype == 1" src="../../static/img/ganhuo.png" alt="" />
          <span v-if="routetype == 2" @click="gotoindex">圈子</span>
          <span v-if="routetype == 2" @click="gotodriedfood">干货</span>
        </div>
        <div class="logo" @click="gotoindex">
          <img src="../../static/img/logo.png" alt="" />
        </div>
        <div class="right">
          <div class="box" @click="searchtype = !searchtype">
            <img src="../../static/img/ss.png" alt="" />
            <span>搜索</span>
          </div>
          <div class="box" @click="gotoinformation">
            <img src="../../static/img/xx.png" alt="" />
            <span>信息</span>
          </div>
          <div class="box" @click="setreleasetype">
            <img src="../../static/img/fb.png" alt="" />
            <span>发布</span>
          </div>
          <div class="Avatar">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                <img :src="Avatar" alt="" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">个人中心</el-dropdown-item>
                <el-dropdown-item command="b">切换账号</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="search" v-if="searchtype">
      <input v-model="form.pageKey" placeholder="试试输入帖子关键字" />
      <div class="button" @click="Searchposts">搜索</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      input: "",
      searchtype: false,
      routetype: 0,
      routename: "index",
      Avatar: "",
      form: {
        token: window.localStorage.getItem("token"),
        pageKey: "",
        pageIndex: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    if (window.localStorage.getItem("Avatar")) {
      this.Avatar = window.localStorage.getItem("Avatar");
    } else {
      this.Avatar = "";
    }
  },
  watch: {
    "$route.path": function (newVal, oldVal) {
      this.routename = newVal;
      console.log(newVal);
      if (newVal == "/index") {
        this.routetype = 0;
      } else if (newVal == "/driedfood") {
        this.routetype = 1;
      } else if (newVal == "/") {
        this.routetype = 0;
      } else {
        this.routetype = 2;
      }
      // if(newVal)
    },
  },
  methods: {
    // 选择退出或主页
    handleCommand(command) {
      if (command == "a") {
        this.gotouserinfo();
      } else {
        // window.localStorage.removeItem("token");
        this.$store.commit("changeDataMut", true);
      }
    },
    // 搜索
    Searchposts() {
      if (this.form.pageKey == "") {
        this.$message({
          message: "请输入帖子关键字",
          type: "warning",
        });
      } else {
        this.$api.common.FindSearchList(this.form).then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$store.commit("changelist", res.data.list);
            this.$store.commit("changetotal", res.data.total);
            if (this.routename != `Searchposts?pageKey=${this.form.pageKey}`) {
              console.log(123213);
              this.$router.push(`Searchposts?pageKey=${this.form.pageKey}`);
            } else {
              console.log(33333333333);
            }
          }
        });
      }
    },
    // 打开发布
    setreleasetype() {
      this.$store.commit("changereleasetype", true);
    },
    gotouserinfo() {
      if (this.routename != "/userinfo") {
        this.$router.push("userinfo");
      }
    },
    gotodriedfood() {
      if (this.routename != "/driedfood") {
        this.$router.push("driedfood");
      }
    },
    gotoinformation() {
      if (this.routename != "/information") {
        this.$router.push("information");
      }
    },
    gotoindex() {
      if (this.routename != "/index") {
        this.$router.push("index");
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import url("./headrr.less");
</style>>
