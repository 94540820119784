import vue from '../main'
import axios from 'axios'

const errorMsg = '服务器错误'

// 测试环境
// axios.defaults.baseURL = 'http://39.101.216.139:8084/'
// 正式环境
axios.defaults.baseURL = 'https://www.xsdyiwu.com/'
// 请求超时时间
axios.defaults.timeout = 1000 * 60 * 2;
// axios.defaults.withCredentials = true 发送一次http预请求 可能存在跨域 因为不是https
// axios.defaults.headers.post['Content-Type'] = 'text/html; charset=UTF-8'

// 请求拦截器
// 请求拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    console.log(response.data.code);
    if (response.data.code == "102" ) {
        vue.$message.error('登录失效');
        vue.$store.commit("changeDataMut", true);
    }
    if (response.data.code == "103") {
        console.log(3123123);
        vue.$message.error('登录失效');
        vue.$store.commit("changeDataMut", true);
    }
    return response
}, function (error) {
    console.log(error);
    message.error("数据错误")
    // 对响应错误做点什么

    return Promise.reject(error)
});
/**
  * get方法，对应get请求
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                vue.$message.error(errorMsg)
                reject(err.data)
            })
    });
}
/**
  * post方法，对应post请求
  * @param {String} url [请求的url地址]
  * @param {Object} params [请求时携带的参数]
  */
function post(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                // vue.$message.error(errorMsg)
                reject(err.data)
            })
    });
}
export {
    post,
    get
}